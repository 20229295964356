import React from "react";
import CTAButton from "../components/CTAButton/CTAButton";
import { UnderlineVector, VerticalLines } from "../components/SVGs/SVGs";

const NotFoundPage = () => {
  return (
    <main>
      <div className="not-found-wrapper">
        <h1>
          You are lost!
          <UnderlineVector color="#ABADAB" />
        </h1>
        <p>
          Maybe you got lost. Not to worry. You can head over to{" "}
          <span>
            Homepage <UnderlineVector color="#0066CC" />
          </span>{" "}
          can’t get enough of. A better place for you to go, surely.
        </p>
        <CTAButton
          type="button"
          size="btn-lg"
          text="Go back to home"
          rounded={true}
          outlined={false}
          btnBG={`es-btn-blue`}
          spacingClasses=""
          additionalClass="noHover"
          hasIcon={false}
          icon=""
          reverse={false}
          isLink={true}
          isShadow={false}
          href="/"
          btnTextColor="var(--white)"
        />
        <div className="vertical-lines-wrapper">
          <VerticalLines />
        </div>
      </div>
    </main>
  );
};

export default NotFoundPage;
